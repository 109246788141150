import React, { useEffect, useState } from 'react';
import { MDBContainer, MDBCol, MDBRow, MDBInput } from 'mdb-react-ui-kit';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
import "./loginview.css";
function Loginview() {
  const navigate = useNavigate();

  useEffect(() => {
    var loggedIn = window.localStorage.getItem("islogged");
    if (loggedIn == "1") {
      navigate('/Products');
    }
  }, []);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handlelogin = () => {
    if (username == "admin" && password == "FiA4K0C8LG") {
      window.localStorage.setItem("islogged", 1)
      navigate('/Products');
    }
    else {
      alert("Please enter Correct details")
    }
  }
  return (
    <MDBContainer className="p-3 my-5 h-custom">
      <MDBRow className='align-items-center'>
        <MDBCol col='12' md='12'>
          <div className='custom_login'>
            <div className="d-flex flex-row align-items-center justify-content-center admin_panel">
              <h2 className="fs-1 fw-semibold">Login</h2>
            </div>
            <label>Username</label>
            <MDBInput wrapperClass='mb-4' id='formControlLg' type='text' size="lg" onChange={(e) => { setUsername(e.target.value) }} />
            <label>Password</label>
            <MDBInput wrapperClass='mb-4' id='formControlLg' type='password' size="lg" onChange={(e) => { setPassword(e.target.value) }} />
            <Button onClick={handlelogin}>Login</Button>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default Loginview;