import "./scanableproductshow.css";
import Sidebar from "../../../components/sidebar/Sidebar";
import { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Spinner } from "react-bootstrap";
import axios from "axios";
import product_img from "../../../components/image/product_img.jpg";
import base64 from "base-64";

export default function ScanableProductShow() {
  const location = useLocation()
  const { productnamebefore } = location.state
  var navigate = useNavigate();
  let { code } = useParams();
  const [status, setstatusdata] = useState(0);
  const [productname, setproductname] = useState("");
  const [brand, setbrand] = useState("");
  const [count, setcount] = useState();
  const [reqdata, setreqdata] = useState([]);
  const [imagedata, setimagedata] = useState([]);
  const auth = "Basic " + base64.encode(process.env.REACT_APP_USER_KEY + ":" + process.env.REACT_APP_PWD_KEY);
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': auth,
  }

  var recycle = Array();

  useEffect((() => {
    axios.post(process.env.REACT_APP_API_URL + "/api/product-sanitised", { code: code, user: 'admin' }, { headers: headers })
      .then(response => {
        setreqdata(response.data.recyclability)
        setimagedata(response.data.imageUrl)
        setproductname(response.data.productName)
        setbrand(response.data.brands)
        setcount(response.data.count)
        setstatusdata(1)
      })
  }), []);

  useEffect(() => {

  }, [status]);

  reqdata.map((data) => {
    for (var key in data) {
      recycle.push(data[key]);
    }
  });
  let loaderstyle = { width: "2rem", height: "2rem", marginLeft: "15%", marginTop: "15%", display: "" }
  let displayshow = { display: "", boxShadow: '2px 2px 10px black', borderRadius: "50%", backgroundColor: "white", width: "3em", height: "3em", marginLeft: "35%", marginTop: "10%", position: "absolute" }

  return (
    <>
      <Sidebar />
      <div className="product">
        <div id="productTitleContainer">
          <Button className="back_btn"><ArrowBackIcon onClick={() => { navigate(-1) }}></ArrowBackIcon></Button>
          <h1 className="productTitle">   {productnamebefore}</h1>
        </div>
        {status ? <div>
          <div className="productTop">
            <div className="productTopRight">
              <div className="productInfoTop">
                {imagedata == "not found" ? <img src={product_img} /> : <img src={imagedata ? imagedata : ""} alt="no image" id="productInfoImg" />}
              </div>
              <div id="productInfoItem">
                <span id="productInfoKey">Product name</span>
                <span id="productInfoValue">: &nbsp;{productname}</span>
              </div>
              <div id="productInfoItem">
                <span id="productInfoKey">Code </span>
                <span id="productInfoValue">: &nbsp;{code}</span>
              </div>
              <div id="productInfoItem">
                <span id="productInfoKey">Brand</span>
                <span id="productInfoValue">: &nbsp;{brand ? brand : "not found"}</span>
              </div>
              <div id="productInfoItem">
                <span id="productInfoKey">Count</span>
                <span id="productInfoValue">: &nbsp;{count ? count : "0"}</span>
              </div>
              <div className="productInfoBottom">


              </div>
            </div>
          </div>

          <div className="productBottom">
            {recycle.length ?
              recycle.map((data, index) => {
                if (!data) return;

                return (<div className="product_desc" key={index}>

                  <div id="productInfoItem">
                    <span id="productInfoKey">Material</span>
                    <span id="productInfoValue">: {data.material == undefined ? data.categories : data.material}</span>
                  </div>
                  <div id="productInfoItem">
                    <span id="productInfoKey">Council Category</span>
                    <span id="productInfoValue">: {data.councilCategory}</span>
                  </div>
                  <div id="productInfoItem">
                    <span id="productInfoKey">Default Guidance</span>
                    <span id="productInfoValue">: {data.defaultGuidance}</span>
                  </div>
                </div>);
              }) :
              'No recycle info available'
            }
          </div></div> : <div style={displayshow} >
          <Spinner animation="border" role="status" style={loaderstyle}>
          </Spinner>
        </div>}</div></>
  );


}
