import "./Materials.css";
import { useState, useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Spinner from 'react-bootstrap/Spinner';
import Sidebar from "../../components/sidebar/Sidebar";
import SearchIcon from '@mui/icons-material/Search';
import swal from 'sweetalert'
import axios from "axios";
import base64 from "base-64";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { MATERIALRECYCLETYPES } from '../mockData/recycleType';

export default function Materials() {
    const [data, setData] = useState({});
    const [load, setload] = useState("");
    const [pageCount, setpageCount] = useState(1);
    const [searchvalue, setsearchvalue] = useState("");
    const [pageNo, setpageNo] = useState(0);
    const [locationRecyclabilityData, setLocationRecyclabilityData] = useState([]);
    const auth = "Basic " + base64.encode(process.env.REACT_APP_USER_KEY + ":" + process.env.REACT_APP_PWD_KEY);
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': auth,
    }

    const handleAutoChange = async (materialId, locationRecyclabilityId, value) => {
        setload("border")
        await axios.post(process.env.REACT_APP_API_URL + "/api/updateMaterials", { materialId, locationRecyclabilityId, value }, { headers: headers });
        setload("")
    };

    const limit = 10;
    const getTotalPages = async (searchData = false) => {
        try {
            let countresult = await axios.post(process.env.REACT_APP_API_URL + "/api/materialsCount", { searchValue: searchData ? "" : searchvalue }, { headers: headers });
            let countresponse = JSON.parse(countresult.request.response)
            let totalpage = Math.ceil(countresponse / limit);
            return totalpage;
        }
        catch (err) {
            if (err.response.status == 404) {
                return 1;
            }
        }
    };

    useEffect(async () => {
        setload("border") //set loader
        const totalPages = await getTotalPages(); // total pages count 
        setpageCount(totalPages); // set total pages count 
        let results = await axios.post(process.env.REACT_APP_API_URL + "/api/materialsList", { pageNumber: pageCount, searchValue: searchvalue }, { headers: headers });
        setData(results.data.data)
        let locationRecyclabilityList = await axios.get(process.env.REACT_APP_API_URL + "/api/locationRecyclabilityAll", { headers: headers });
        setLocationRecyclabilityData(locationRecyclabilityList.data.data);
        setload("")
    }, []);

    const handlePageClick = async (data) => {
        setpageNo(data.selected);
        setData({})
        setload("border")
        let currentPage = data.selected + 1;
        const totalPages = await getTotalPages(); // total pages count 
        setpageCount(totalPages); // set total pages count 
        const productsdata = await fetchlist(currentPage);
        setData(productsdata);
        setload("");
    };

    const fetchlist = async (currentPage) => {
        let results = await axios.post(process.env.REACT_APP_API_URL + "/api/materialsList", { pageNumber: currentPage, searchValue: searchvalue }, { headers: headers });
        setload("")
        return results.data.data;
    };

    const handlesubmit = async (e) => {
        e.preventDefault();
        setpageNo(0)
        setData({})
        setload("border")
        const totalPages = await getTotalPages(); // total pages count 
        setpageCount(totalPages); // set total pages count 
        if (totalPages >= 1) {
            let results = await axios.post(process.env.REACT_APP_API_URL + "/api/materialsList", { pageNumber: 1, searchValue: searchvalue }, { headers: headers });
            if (results.data.data.length) {
                setload("")
                setData(results.data.data)
            }
            else {
                swal({
                    title: "Data not found",
                    icon: "error",
                });
                onreset();
                setload("");
            }
        }
        else {
            swal({
                title: "Data not found",
                icon: "error",
            });
            onreset();
            setload("");
        }
    }

    const handlechange = async (e) => {
        e.preventDefault();
        if (e.target.value == "") {
            setsearchvalue(e.target.value.trim())
        } else {
            setsearchvalue(e.target.value.trim())
        }
    }

    const onreset = async () => {
        setpageNo(0);
        setsearchvalue("");
        setload("border")
        const totalPages = await getTotalPages(true); // total pages count 
        setpageCount(totalPages); // set total pages count 

        let results = await axios.post(process.env.REACT_APP_API_URL + "/api/materialsList", { pageNumber: 1, searchValue: "" }, { headers: headers });
        setData(results.data.data);
        setload("")
    };


    let loaderstyle = { width: "2rem", height: "2rem", marginLeft: "15%", marginTop: "15%", display: "", position: "absolute" }
    let loadernostyle = { width: "0rem", height: "0rem", display: "none", position: "absolute" }
    let displayhide = { display: "none" }
    let displayshow = { display: "", boxShadow: '2px 2px 10px black', borderRadius: "50%", backgroundColor: "white", width: "3em", height: "3em", marginLeft: "35%", marginTop: "15%", position: "absolute", zIndex: 100000 }

    return (
        <>
            <Sidebar />
            <div className="userList">
                <div className="search_bar_recycli">

                    <form onSubmit={(e) => {
                        e.preventDefault();
                        if (!searchvalue == "") {
                            handlesubmit(e)
                        }
                    }}>

                        <div>
                            <input type="text" name="searchvalue" className="userUpdateInputscan" placeholder=" Search" onChange={(e) => { handlechange(e) }} value={searchvalue}></input>
                            <Button className="search_btn" type="submit"><SearchIcon></SearchIcon></Button>
                            <Button className="reset_btn" style={{ marginLeft: '10px' }} onClick={onreset}>Reset</Button>
                        </div>
                    </form>
                </div>

                <div style={load ? displayshow : displayhide}>
                    <Spinner animation={load} role="status" style={load ? loaderstyle : loadernostyle}>
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
                <div className="view">
                    <div className="wrapper">
                        <table className="table">
                            <thead>
                                <tr>
                                    {/* <th>Id</th> */}
                                    <th className='sticky-col first-col'>Material</th>
                                    <th className='sticky-col second-col'>CouncilCategory</th>
                                    <th className='sticky-col third-col'>Default Guidance</th>
                                    {locationRecyclabilityData.length ? locationRecyclabilityData.map((element, index) => {
                                        return (
                                            <th>{element?.address}</th>
                                        )
                                    }) : ""}
                                </tr>
                            </thead>
                            <tbody>
                                {data.length ? data.map((element, index) => {
                                    return (<tr key={index}>
                                        <td className='sticky-col first-col'>{element.material}</td>
                                        <td className='sticky-col second-col'>{element.councilCategory}</td>
                                        <td className='sticky-col third-col'>{element.defaultGuidance}</td>
                                        {locationRecyclabilityData.map((locationRecyclability, index) => {
                                            return (
                                                <td>
                                                    <Autocomplete
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={MATERIALRECYCLETYPES}
                                                        sx={{ width: 300 }}
                                                        defaultValue={Object.keys(element).includes(locationRecyclability._id) ? element[locationRecyclability._id] : ""}
                                                        onChange={(event, value) => {
                                                            handleAutoChange(element._id, locationRecyclability._id, value)
                                                        }}
                                                        renderInput={(params) => <TextField {...params} label="Choose Recyclability" />}
                                                    />
                                                </td>
                                            )
                                        })}
                                    </tr>)
                                }) : <tr><td colSpan={4}><center>No data found</center></td></tr>}
                            </tbody>
                        </table>
                    </div>
                </div>


                <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    forcePage={pageNo}
                    containerClassName={"pagination justify-content-space-between z-10000"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                />
            </div></>
    );
}
