import "./createLocationRecyclability.css";
import axios from 'axios';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import React from 'react';
import { useState, useEffect } from 'react';
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import Sidebar from "../../../components/sidebar/Sidebar";
import swal from 'sweetalert';
import base64 from "base-64";

export default function CreateLocationRecyclability() {
  var navigate = useNavigate();
  const [value, setValue] = useState("");
  const [addressInfo, setAddress] = useState({
    lat: 0.0,
    long: 0.0,
    address: "",
    addressDetails: {}
  })
  const auth = "Basic " + base64.encode(process.env.REACT_APP_USER_KEY + ":" + process.env.REACT_APP_PWD_KEY);
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': auth,
  }

  useEffect(() => {
    async function fetch() {
      var geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({
        'address': value ? value.value.description : ""
      }, function (results, status) {

        if (status === window.google.maps.GeocoderStatus.OK) {
          setAddress((prevValue) => {
            return {
              ...prevValue,
              lat: results[0].geometry.location.lat(),
              long: results[0].geometry.location.lng(),
              address: value.value.description,
              addressDetails: results
            }
          })
        }
      });
    }
    fetch();
  }, [value]);

  const submit = (e) => {
    e.preventDefault();
    
    if (addressInfo.lat && addressInfo.long && addressInfo.address) {
      axios.post(process.env.REACT_APP_API_URL + "/api/storeLocationRecyclability", { lat: addressInfo.lat, long: addressInfo.long, address: addressInfo.address, addressDetails: addressInfo.addressDetails }, { headers: headers })
        .then(response => {
          navigate(-1)
        })
        .catch((err) => {
          if (err.response.status == 401) {
            swal({
              title: "invalid token",
              icon: "warning",
            });
          }
        });
    }
    else {
      // alert('please fill details');
      swal({
        title: "All fields are required",
        icon: "error",
      });
    }

  };

  return (
    <>
      <Sidebar />
      <div className="user">
        <div className="userTitleContainer">
          <h1 className="userTitle">Add Location recyclability</h1>
          {/* <Link to="/newUser">
          <button className="userAddButton">Create</button>
        </Link> */}
        </div>
        <div className="userContainer">
          <div className="userUpdate">
            <form className="userUpdateForm">
              <div className="userUpdateLeft">
                <div className="userUpdateItem">
                  <label>Address</label>
                  <GooglePlacesAutocomplete
                    selectProps={{
                      value,
                      onChange: setValue

                    }}
                  />
                  <input

                    name="address"
                    value={addressInfo.address}
                    type="hidden"
                    placeholder="Enter address"
                    className="userUpdateInput"
                    onChange={() => { }}
                  />
                </div>
                <div className="userUpdateItem">
                  <label>latitude</label>
                  <input
                    name="lat"
                    value={addressInfo.lat}
                    type="text"
                    readOnly
                    className="userUpdateInput"
                    onChange={() => { }}
                  />
                </div>
                <div className="userUpdateItem">
                  <label>longtitude</label>
                  <input
                    name="long"
                    value={addressInfo.long}
                    readOnly
                    type="text"
                    className="userUpdateInput"
                    onChange={() => { }}
                  />
                </div>
                
                <Button className="add_new" type="submit" onClick={submit} >Submit</Button>
              </div>
            </form>
          </div>
        </div>
      </div></>
  );
}
