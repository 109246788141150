import React from "react";
import "./topbar.css";
import LogoutIcon from '@mui/icons-material/Logout';
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import logo from "../image/logo.png";
export default function Topbar() {
  const navigate = useNavigate();
  var loggedIn = window.localStorage.getItem("islogged");
  const handlelogout = () => {
    window.localStorage.setItem("islogged", 0)
    navigate('/');
  }
  return (
    <div className="topbar">
      <div className="topbarWrapper">
        <div className="topLeft">
          <span className="logo"><img src={logo} alt="logo" width={50} />Do I Recycle</span>
        </div>
        <div className="topRight">
          {loggedIn != false ? <Button onClick={handlelogout}><LogoutIcon></LogoutIcon></Button> : ""}
        </div>
      </div>
    </div>
  );
}
