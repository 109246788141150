import "./editrecyclibilitycentre.css";
import axios from 'axios';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import React from 'react';
import { useState, useEffect } from 'react';
import { Button } from "react-bootstrap";
import {useNavigate, useLocation} from 'react-router-dom';
import Sidebar from "../../../components/sidebar/Sidebar";

export default function EditRecyclibilityCentre() {
  
  const location = useLocation();
  const { id,lat,long,add,detail } = location.state;
  var navigate = useNavigate();
  const [value, setValue] = useState("");
  const [addressInfo, setAddress] = useState({
    lat: lat,
    long: long,
    address: add,
    furtherdetails: detail,
})

useEffect(() => {
  async function fetch(){
      var geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({
          'address': value?value.value.description:""
        }, function(results, status) {
      
          if (status === window.google.maps.GeocoderStatus.OK) {
              setAddress((prevValue) => {
                  return {
                      ...prevValue,
                      lat: results[0].geometry.location.lat(),
                      long: results[0].geometry.location.lng(),
                      address: value.value.description,
                    
                  }
              })
          }
        });
  }
  fetch();
}, [value]);

const submit = (e) => {
  e.preventDefault();
  if(addressInfo.lat && addressInfo.long && addressInfo.address && addressInfo.furtherdetails){
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Basic dFZsT0RyR2hFejRldTRCZFpkdUFaTkpIYnRZSDZ3MEU0QjFDVGRkOmRaWVRJRVFWZjJYd1NwY3V4NkVkTTM2ZnY1Vkh1aXdZbW5DMGJKbkg='
    }
    axios.post(process.env.REACT_APP_API_URL+"/api/editcenter", { editid:id,lat:addressInfo.lat, long:addressInfo.long, address: addressInfo.address, furtherdetails:addressInfo.furtherdetails},{headers: headers})
    .then(() => {
        navigate(-1)
  });
  }
  else{
    alert('please fill details');
  }

};

const inputEvent = (event) => {
  setAddress((prevValue) => {
      return {
          ...prevValue,
          [event.target.name]: event.target.value
      }
  })
}

  return (
    <>
    <Sidebar />
    <div className="user">
      <div className="userTitleContainer">
        <h1 className="userTitle">Recyclibility Centre</h1>
      </div>
      <div className="userContainer">
        <div className="userUpdate">
          <form className="userUpdateForm">
            <div className="userUpdateLeft">
              <div className="userUpdateItem">
                <label>Address</label>
                <GooglePlacesAutocomplete 
                selectProps={{
                    value,
                    onChange: setValue,
                    defaultInputValue:add
                    
                }}
            />
                <input
                
                  name="address" 
                  value={addressInfo.address}
                  type="hidden"
                  placeholder="Enter address"
                  className="userUpdateInput"
                  onChange={()=>{}}
                />
              </div>
              <div className="userUpdateItem">
                <label>latitude</label>
                <input
                  name="lat" 
                  value={addressInfo.lat}
                  type="text"
                  readOnly
                  className="userUpdateInput"
                  onChange={()=>{}}
                />
              </div>
              <div className="userUpdateItem">
                <label>longtitude</label>
                <input
                  name="long" 
                  value={addressInfo.long} 
                  readOnly
                  type="text"
                  className="userUpdateInput"
                  onChange={()=>{}}
                />
              </div>
              <div className="userUpdateItem">
                <label>Further Details</label>
                <textarea
                  name="furtherdetails"
                  onChange={inputEvent}
                  value={addressInfo.furtherdetails}
                  type="text"
                  placeholder="Enter details"
                  className="userUpdateInputText"
                />
              </div>
              <Button className="add_new" type="submit" onClick={submit} >Submit</Button>
            </div>
          </form>
        </div>
      </div>
    </div></>
  );
}
