
import Topbar from "./components/topbar/Topbar";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import ScanableProduct from "./pages/scanableProduct/scanableProduct";
import ScanableProductShow from "./pages/scanableProduct/show/scanableproductshow";
import ScanableProductCreate from "./pages/scanableProduct/create/scanableproductcreate";
import ScanableProductEdit from "./pages/scanableProduct/edit/scanableproductedit";
import NonScanableProduct from "./pages/nonscanableproduct/nonscanableproduct";
import NonScanableProductShow from "./pages/nonscanableproduct/show/nonscanableproductshow";
import RecyclibilityCentre from "./pages/recyclibilitycentre/recyclibilitycentre";
import CreateRecyclibilityCentre from "./pages/recyclibilitycentre/create/createrecyclibilitycentre";
import RecyclibilityCentreShow from './pages/recyclibilitycentre/show/recyclibilitycentreshow';
import RecyclibilityCentreEdit from './pages/recyclibilitycentre/edit/editrecyclibilitycentre';
import Loginview from './pages/login/loginview';
import NonScanableProductCreate from "./pages/nonscanableproduct/create/nonscanableproductcreate";
import LocationRecyclability from "./pages/locationRecyclability/locationRecyclability";
import CreateLocationRecyclability from "./pages/locationRecyclability/create/createLocationRecyclability";
import LocationRecyclabilityShow from "./pages/locationRecyclability/show/LocationRecyclabilityShow";
import LocationRecyclabilityEdit from "./pages/locationRecyclability/edit/LocationRecyclabilityEdit";
import Categories from "./pages/Categories/Categories";
import Materials from "./pages/Materials/Materials";

function App() {
  return (
    <Router>
      <Topbar />
      <div className="container-fluid">
        <div className="row">
          <Routes>
            <Route exact path="/" element={<Loginview />} />
            <Route exact path="/Products" element={<ScanableProduct />} />
            <Route exact path="/Products/:code" element={<ScanableProductShow />} />
            <Route exact path="/Products/Create" element={<ScanableProductCreate />} />
            <Route exact path="/Products/Edit" element={<ScanableProductEdit />} />
            <Route exact path="/NotFoundProducts" element={<NonScanableProduct />} />
            <Route exact path="/NotFoundProductsCreate" element={<NonScanableProductCreate />} />
            <Route exact path="/NotFoundProducts/:id" element={<NonScanableProductShow />} />
            <Route exact path="/Location" element={<RecyclibilityCentre />} />
            <Route exact path="/Location/Create" element={<CreateRecyclibilityCentre />} />
            <Route exact path="/Location/:id" element={<RecyclibilityCentreShow />} />
            <Route exact path="/Location/Edit" element={<RecyclibilityCentreEdit />} />

            <Route exact path="/locationRecyclability" element={<LocationRecyclability />} />
            <Route exact path="/locationRecyclability/Create" element={<CreateLocationRecyclability />} />
            <Route exact path="/locationRecyclability/:id" element={<LocationRecyclabilityShow />} />
            <Route exact path="/locationRecyclability/edit" element={<LocationRecyclabilityEdit />} />

            <Route exact path="/categories" element={<Categories />} />
            <Route exact path="/materials" element={<Materials />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
