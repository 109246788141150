import { useLocation } from "react-router-dom";
import "./nonscanableproductcreate.css";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../components/sidebar/Sidebar";
import swal from "sweetalert";
import base64 from "base-64";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

export default function NonScanableProductCreate() {
  const location = useLocation();

  const {
    product_name,
    code,
    brands,
    packagings,
    categories_tags,
    manualFlag,
    image,
  } = location.state.element;

  const [load, setload] = useState("");
  var navigate = useNavigate();
  const [materialinput, setMaterialinput] = useState(
    packagings ? packagings : []
  );
  const [categoriesinput, setCategoriesinput] = useState(
    categories_tags ? categories_tags : []
  );
  const [productInfo, setProductInfo] = useState({
    name: product_name ? product_name : "",
    code: code ? code : "",
    brand: brands ? brands : "",
    image: manualFlag ? image : "",
  });
  const addMaterialFields = () => {
    let object = "";
    setMaterialinput([...materialinput, object]);
  };

  const removeMaterialFields = (index) => {
    let data = [...materialinput];
    data.splice(index, 1);
    setMaterialinput(data);
  };

  const handleMaterialChange = (index, event) => {
    let data = [...materialinput];
    data[index] = event.target.value;
    setMaterialinput(data);
  };

  const addCatgoriesFields = () => {
    let object = "";
    setCategoriesinput([...categoriesinput, object]);
  };

  const removeCatgoriesFields = (index) => {
    let data = [...categoriesinput];
    data.splice(index, 1);
    setCategoriesinput(data);
  };

  const handleCategoriesChange = (index, event) => {
    let datas = [...categoriesinput];
    datas[index] = event.target.value;
    setCategoriesinput(datas);
  };

  const inputEvent = (event) => {
    setProductInfo((prevValue) => {
      return {
        ...prevValue,
        [event.target.name]: event.target.name == "image" ? event.target.files[0] : event.target.value,
      };
    });
  };

  const auth =
    "Basic " +
    base64.encode(
      process.env.REACT_APP_USER_KEY + ":" + process.env.REACT_APP_PWD_KEY
    );
  const headers = {
    "Content-Type": "application/json",
    Authorization: auth,
  };

  const submit = (event) => {
    setload("border");
    event.preventDefault();
    const formData = new FormData();
    formData.append("name", productInfo.name);
    formData.append("code", productInfo.code);
    formData.append('brand', productInfo.brand);
    formData.append('image', productInfo.image);
    formData.append('material', materialinput);
    formData.append('categories', categoriesinput);

    if (formData) {
      axios
        .post(
          process.env.REACT_APP_API_URL + "/api/storescanableproduct",
          formData,
          { headers: headers }
        )
        .then((response) => {
          setload("");
          navigate(-1);
        })
        .catch((err) => {
          if (err.response.status == 401) {
            swal({
              title: "invalid token",
              icon: "warning",
            });
          }
          if (err.response.status == 404) {
            swal({
              title: "Product Already Stored",
              icon: "warning",
            });
          }
          setload("");
        });
    } else {
      alert("please fill details");
      setload("");
    }
  };

  return (
    <>
      <Sidebar />
      <div className="user">
        <div className="userTitleContainer">
          <h1 className="userTitle">Add Product</h1>
        </div>
        <div className="userContainer">
          <div className="userUpdate">
            <div className="userUpdateLeft">
              <div className="userUpdateItem">
                <label>Product Name</label>
                <input
                  name="name"
                  value={productInfo.name}
                  type="text"
                  className="userUpdateMaterialInput"
                  onChange={inputEvent}
                />
              </div>
              <div className="userUpdateItem">
                <label>Brand</label>
                <input
                  name="brand"
                  value={productInfo.brand}
                  type="text"
                  className="userUpdateMaterialInput"
                  onChange={inputEvent}
                />
              </div>
              <div className="userUpdateItem">
                <label>Code</label>
                <input
                  name="code"
                  value={productInfo.code}
                  type="text"
                  className="userUpdateMaterialInput"
                  onChange={inputEvent}
                />
              </div>
              {materialinput.map((form, index) => {
                return (
                  <div key={index} className="userUpdateItem">
                    {index == 0 ? <label>Material Types</label> : ""}
                    <span>
                      <input
                        name="materialinput"
                        type="text"
                        readOnly={form[2] == ":" && !form.startsWith("en")}
                        className="userUpdateMaterialInput"
                        value={form[2] == ":" ? form.slice(3) : form.slice(0)}
                        onChange={(event) => {
                          handleMaterialChange(index, event);
                        }}
                      />
                      <Button
                        className="deleteButton"
                        onClick={() => removeMaterialFields(index)}
                      >
                        <CloseIcon />
                      </Button>
                    </span>
                  </div>
                );

                // }
              })}
              <Button className="add" onClick={addMaterialFields}>
                Add Material <AddIcon />
              </Button>
              {categoriesinput.map((form, index) => {
                return (
                  <div key={index} className="userUpdateItem">
                    {index == 0 ? <label>Categories Types</label> : ""}
                    <span>
                      <input
                        name="categoriesinput"
                        readOnly={form[2] == ":" && !form.startsWith("en")}
                        type="text"
                        className="userUpdateMaterialInput"
                        value={form[2] == ":" ? form.slice(3) : form.slice(0)}
                        onChange={(event) =>
                          handleCategoriesChange(index, event)
                        }
                      />
                      <Button
                        className="deleteButton"
                        onClick={() => removeCatgoriesFields(index)}
                      >
                        <CloseIcon />
                      </Button>
                    </span>
                  </div>
                );
                // }
              })}

              <Button className="add" onClick={addCatgoriesFields}>
                Add Category <AddIcon />
              </Button>

              {manualFlag == undefined ? (
                <div className="userUpdateItem">
                  <label>Image</label>
                  <input
                    name="image"
                    type="file"
                    className="userUpdateMaterialInput"
                    onChange={inputEvent}
                  />
                </div>
              ) : (
                <div>
                  <div className="productInfoTop">
                    {image == undefined ? (
                      ""
                    ) : (
                      <img src={image} alt="no image" id="productInfoImg" />
                    )}
                  </div>
                  <div className="userUpdateItem">
                    <label>Image</label>
                    <input
                      name="image"
                      type="file"
                      className="userUpdateMaterialInput"
                      onChange={inputEvent}
                    />
                  </div>
                </div>
              )}

              <Button className="add_new" type="submit" onClick={submit}>
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
