import "./scanableproductcreate.css";
import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import Sidebar from "../../../components/sidebar/Sidebar";
import Spinner from 'react-bootstrap/Spinner';
import swal from 'sweetalert';
import base64 from "base-64";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

export default function ScanableProductCreate() {
  var navigate = useNavigate();
  const [productInfo, setProductInfo] = useState({
    name: "",
    brand: "",
    code: "",
    image: "",
  })
  const [load, setload] = useState("");
  const [materialinput, setMaterialinput] = useState([{ materialinput: '' }]);
  const [categoriesinput, setCategoriesinput] = useState([{ categoriesinput: '' }]);

  const auth = "Basic " + base64.encode(process.env.REACT_APP_USER_KEY + ":" + process.env.REACT_APP_PWD_KEY);
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': auth,
  }

  const addMaterialFields = () => {
    let object = { materialinput: '' }
    setMaterialinput([...materialinput, object])
  }

  const removeMaterialFields = (index) => {
    let data = [...materialinput];
    data.splice(index, 1)
    setMaterialinput(data)
  }

  const addCatgoriesFields = () => {
    let object = { categoriesinput: '' }
    setCategoriesinput([...categoriesinput, object])
  }

  const removeCatgoriesFields = (index) => {
    let data = [...categoriesinput];
    data.splice(index, 1)
    setCategoriesinput(data)
  }
  const handleMaterialChange = (index, event) => {
    let data = [...materialinput];
    data[index] = event.target.value;
    setMaterialinput(data);
  }

  const handleCategoriesChange = (index, event) => {
    let data = [...categoriesinput];
    data[index] = event.target.value;
    setCategoriesinput(data);
  }

  const inputEvent = (event) => {
    setProductInfo((prevValue) => {
      return {
        ...prevValue,
        [event.target.name]: event.target.name == "image" ? event.target.files[0] : event.target.value
      }
    })
  }

  const submit = (event) => {
    setload("border")
    event.preventDefault();
    const formData = new FormData()
    formData.append('image', productInfo.image);
    formData.append('name', productInfo.name);
    formData.append('brand', productInfo.brand);
    formData.append('code', productInfo.code);
    formData.append('material', materialinput);
    formData.append('categories', categoriesinput);

    if (formData) {

      axios.post(process.env.REACT_APP_API_URL + "/api/storescanableproduct", formData, { headers: headers })
        .then(() => {
          setload("")
          navigate(-1)
        })
        .catch((err) => {
          if (err.response.status == 401) {
            swal({
              title: "invalid token",
              icon: "warning",
            });
          }
          if (err.response.status == 404) {
            swal({
              title: "Product Already Stored",
              icon: "warning",
            });
          }
          setload("")
        });
    }
    else {
      alert('please fill details');
      setload("")
    }

  }

  let loaderstyle = { width: "2rem", height: "2rem", marginLeft: "15%", marginTop: "15%", display: "", position: "absolute" }
  let loadernostyle = { width: "0rem", height: "0rem", display: "none", position: "absolute" }
  let displayhide = { display: "none" }
  let displayshow = { display: "", boxShadow: '2px 2px 10px black', borderRadius: "50%", backgroundColor: "white", width: "3em", height: "3em", marginLeft: "35%", marginTop: "15%", position: "absolute" }
  return (
    <>
      <Sidebar />
      <div className="user">
        <div className="userTitleContainer">
          <h1 className="userTitle">Product add</h1>
        </div>
        <div style={load ? displayshow : displayhide}>
          <Spinner animation={load} role="status" style={load ? loaderstyle : loadernostyle}>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
        <div className="userContainer">
          <div className="userUpdate">

            <div className="userUpdateLeft">
              <div className="userUpdateItem">
                <label>Product Name</label>
                <input
                  name="name"
                  value={productInfo.name}
                  type="text"
                  className="userUpdateMaterialInput"
                  onChange={inputEvent}
                />
              </div>
              <div className="userUpdateItem">
                <label>Brand</label>
                <input
                  name="brand"
                  value={productInfo.brand}
                  type="text"
                  className="userUpdateMaterialInput"
                  onChange={inputEvent}
                />
              </div>
              <div className="userUpdateItem">
                <label>Code</label>
                <input
                  name="code"
                  value={productInfo.code}
                  type="text"
                  className="userUpdateMaterialInput"
                  onChange={inputEvent}
                />
              </div>
              {
                materialinput.map((form, index) => {
                  return (
                    <div key={index} className="userUpdateItem">
                      {index == 0 ? <label>Material Types</label> : ''}
                      <span><input
                        name="materialinput"
                        type="text"
                        className="userUpdateMaterialInput"
                        onChange={event => handleMaterialChange(index, event)}
                      />
                        <Button className="deleteButton" onClick={() => removeMaterialFields(index)}><CloseIcon /></Button></span>
                    </div>
                  )
                })
              }

              <Button className="add" onClick={addMaterialFields}>Add Material <AddIcon /></Button>
              {
                categoriesinput.map((form, index) => {
                  return (
                    <div key={index} className="userUpdateItem">
                      {index == 0 ? <label>Categories Types</label> : ''}
                      <span><input
                        name="Categories"
                        type="text"
                        className="userUpdateMaterialInput"
                        onChange={event => handleCategoriesChange(index, event)}
                      />
                        <Button className="deleteButton" onClick={() => removeCatgoriesFields(index)}><CloseIcon /></Button></span>
                    </div>
                  )
                })
              }

              <Button className="add" onClick={addCatgoriesFields}>Add Category <AddIcon /></Button>

              <div className="userUpdateItem">
                <label>Image</label>
                <input
                  name="image"
                  type="file"
                  className="userUpdateMaterialInput"
                  onChange={inputEvent}
                />
              </div>
              <Button className="add_new" type="submit" onClick={submit}>Submit</Button>
            </div>

          </div>
        </div>
      </div></>
  );
}
