import "./locationRecyclability.css";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Spinner from 'react-bootstrap/Spinner';
import Sidebar from "../../components/sidebar/Sidebar";
import SearchIcon from '@mui/icons-material/Search';
import swal from 'sweetalert'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import axios from "axios";
import base64 from "base-64";

export default function LocationRecyclability() {
  const [data, setData] = useState({});
  const [load, setload] = useState("");
  const [pageCount, setpageCount] = useState(1);
  const [searchvalue, setsearchvalue] = useState("");
  const [pageNo, setpageNo] = useState(0);
  const auth = "Basic " + base64.encode(process.env.REACT_APP_USER_KEY + ":" + process.env.REACT_APP_PWD_KEY);
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': auth,
  }

  const limit = 10;
  const getTotalPages = async (searchData = false) => {
    try {
      let countresult = await axios.post(process.env.REACT_APP_API_URL + "/api/locationRecyclabilityCount", { searchValue: searchData ? "" : searchvalue }, { headers: headers });
      let countresponse = JSON.parse(countresult.request.response)
      let totalpage = Math.ceil(countresponse / limit);
      return totalpage;
    }
    catch (err) {
      if (err.response.status == 404) {
        return 1;
      }
    }
  };

  useEffect(async () => {
    setload("border") //set loader
    const totalPages = await getTotalPages(); // total pages count 
    setpageCount(totalPages); // set total pages count 
    let results = await axios.post(process.env.REACT_APP_API_URL + "/api/locationRecyclabilitylist", { pageNumber: pageCount, searchValue: searchvalue }, { headers: headers });
    setData(results.data.data)
    setload("")
  }, []);

  const handlePageClick = async (data) => {
    setpageNo(data.selected);
    setData({})
    setload("border")
    let currentPage = data.selected + 1;
    const totalPages = await getTotalPages(); // total pages count 
    setpageCount(totalPages); // set total pages count 
    const productsdata = await fetchlist(currentPage);
    setData(productsdata);
    setload("");
  };

  const fetchlist = async (currentPage) => {
    let results = await axios.post(process.env.REACT_APP_API_URL + "/api/locationRecyclabilityList", { pageNumber: currentPage, searchValue: searchvalue }, { headers: headers });
    setload("")
    return results.data.data;
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    setpageNo(0)
    setData({})
    setload("border")
    const totalPages = await getTotalPages(); // total pages count 
    setpageCount(totalPages); // set total pages count 
    if (totalPages >= 1) {
      let results = await axios.post(process.env.REACT_APP_API_URL + "/api/locationRecyclabilityList", { pageNumber: 1, searchValue: searchvalue }, { headers: headers });
      if (results.data.data.length) {
        setload("")
        setData(results.data.data)
      }
      else {
        swal({
          title: "Data not found",
          icon: "error",
        });
        onreset();
        setload("");
      }
    }
    else {
      swal({
        title: "Data not found",
        icon: "error",
      });
      onreset();
      setload("");
      // sethideonsearch(1)
    }
  }

  const handlechange = async (e) => {
    e.preventDefault();
    if (e.target.value == "") {
      setsearchvalue(e.target.value.trim())
    } else {
      setsearchvalue(e.target.value.trim())
    }
  }

  const deleterecyclibilitycenter = async (id, index) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          const url = `${process.env.REACT_APP_API_URL}/api/deleteLocationRecyclability/${id}`;
          const options = {
            method: 'delete',
            headers: headers,
          };
          fetch(url, options)
            .then(response => response.json())    // one extra step
            .then(datas => {
              if (datas.status == 200) {
                const list = [...data]
                list.splice(index, 1)
                setData(list)
              }
            })
          swal("success! Location Recyclability has been deleted!", {
            icon: "success",
          });
        } else {
          // swal("Your imaginary file is safe!");
        }
      });


  };

  const onreset = async () => {
    setpageNo(0);
    setsearchvalue("");
    setload("border")
    const totalPages = await getTotalPages(true); // total pages count 
    setpageCount(totalPages); // set total pages count 

    let results = await axios.post(process.env.REACT_APP_API_URL + "/api/locationRecyclabilityList", { pageNumber: 1, searchValue: "" }, { headers: headers });
    setData(results.data.data);
    setload("")
  };


  let loaderstyle = { width: "2rem", height: "2rem", marginLeft: "15%", marginTop: "15%", display: "", position: "absolute" }
  let loadernostyle = { width: "0rem", height: "0rem", display: "none", position: "absolute" }
  let displayhide = { display: "none" }
  let displayshow = { display: "", boxShadow: '2px 2px 10px black', borderRadius: "50%", backgroundColor: "white", width: "3em", height: "3em", marginLeft: "35%", marginTop: "15%", position: "absolute" }

  return (
    <>
      <Sidebar />
      <div className="userList">
        <div className="search_bar_recycli">
          <Link to={"/locationRecyclability/Create"} >
            <button type="button" className="userListEdit" > &nbsp;&nbsp;Add New</button>
          </Link>

          <form onSubmit={(e) => {
            e.preventDefault();
            if (!searchvalue == "") {
              handlesubmit(e)
            }
          }}>

            <div>
              <input type="text" name="searchvalue" className="userUpdateInputscan" placeholder=" Search" onChange={(e) => { handlechange(e) }} value={searchvalue}></input>
              <Button className="search_btn" type="submit"><SearchIcon></SearchIcon></Button>
              <Button className="reset_btn" style={{ marginLeft: '10px' }} onClick={onreset}>Reset</Button>
            </div>
          </form>
        </div>

        <div style={load ? displayshow : displayhide}>
          <Spinner animation={load} role="status" style={load ? loaderstyle : loadernostyle}>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
        <div className="custom_data_table">
          <Table>
            <thead>
              <tr>
                <th>Address</th>
                <th>Lat</th>
                <th>Long</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.length ? data.map((element, index) => {
                return (<tr key={index}>
                  <td>{element.address}</td>
                  <td>{element.lat}</td>
                  <td>{element.long}</td>
                  <td>
                    <>
                      <Link to={"/locationRecyclability/" + element._id}>
                        <button className="viewButton"><VisibilityIcon /></button>
                      </Link>
                      <Link to={"/locationRecyclability/edit"} state={{ id: element._id, lat: element.lat, long: element.long, add: element.address }}>
                        <button className="editButton"><EditIcon /></button>
                      </Link>
                      <button className="deleteButton" ><DeleteIcon onClick={() => deleterecyclibilitycenter(element._id, index)} /></button>
                    </>
                  </td>
                </tr>)
              }) : <tr><td colSpan={4}><center>No data found</center></td></tr>}
            </tbody>
          </Table>
        </div>


        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          forcePage={pageNo}
          containerClassName={"pagination justify-content-space-between"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      </div></>
  );
}
