import "./recyclibilitycentreshow.css";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Spinner, Button } from "react-bootstrap";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Sidebar from "../../../components/sidebar/Sidebar";
import base64 from "base-64";

export default function RecyclibilityCentreShow() {
  var navigate = useNavigate();
  let { id } = useParams();
  const [data, setData] = useState();
  const [load, setload] = useState("");
  const auth = "Basic " + base64.encode(process.env.REACT_APP_USER_KEY + ":" + process.env.REACT_APP_PWD_KEY);
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': auth,
  }
  useEffect(() => {
    setload("border")
    const url = `${process.env.REACT_APP_API_URL}/api/createrecyclibilitycentre/show/${id}`;
    const options = {
      method: 'Get',
      headers: headers,
    };
    fetch(url, options)
      .then(response => response.json())    // one extra step
      .then(datas => {
        setData(datas)
      })
    setload("");
  }, []);
  let loaderstyle = { width: "2rem", height: "2rem", marginLeft: "15%", marginTop: "15%", display: "" }
  let displayshow = { display: "", boxShadow: '2px 2px 10px black', borderRadius: "50%", backgroundColor: "white", width: "3em", height: "3em", marginLeft: "35%", marginTop: "10%", position: "absolute" }

  return (
    <><Sidebar />

      <div className="product">
        <div id="productTitleContainer">
          <Button className="back_btn"><ArrowBackIcon onClick={() => { navigate(-1) }}></ArrowBackIcon></Button>
          <h1 className="productTitle">Address details</h1>
        </div>
        {data ?
          <div className="productTop">
            <div className="productTopRight">
              <div id="productInfoItem">
                <span id="productInfoKey">Address</span>
                <span id="productInfoValue"> : &nbsp;{data[0].address}</span>
              </div>
              <div id="productInfoItem">
                <span id="productInfoKey">latitude</span>
                <span id="productInfoValue">: &nbsp;{data[0].lat}</span>
              </div>
              <div id="productInfoItem">
                <span id="productInfoKey">longitude</span>
                <span id="productInfoValue">: &nbsp;{data[0].long}</span>
              </div>
              <div id="productInfoItem">
                <span id="productInfoKey">Further details</span>
                <span id="productInfoValue">: &nbsp;{data[0].furtherdetails}</span>
              </div>
              <div className="productInfoBottom">
              </div>
            </div>
          </div> : <div style={displayshow} >
            <Spinner animation="border" role="status" style={loaderstyle}>
            </Spinner>
          </div>}</div></>
  );
}
