import "./scanableProduct.css";
import Sidebar from "../../components/sidebar/Sidebar";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Spinner from 'react-bootstrap/Spinner';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import swal from 'sweetalert';
import axios from "axios";
import base64 from "base-64";

export default function ScanableProduct() {
  const [data, setData] = useState({});
  const [load, setload] = useState("");
  const [pageCount, setpageCount] = useState(1);
  const [searchvalue, setsearchvalue] = useState("");
  const [pageNo, setpageNo] = useState(0);
  let limit = 10;
  const auth = "Basic " + base64.encode(process.env.REACT_APP_USER_KEY + ":" + process.env.REACT_APP_PWD_KEY);
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': auth,
  }

  const getTotalPages = async (searchData = false) => {
    try {
      let countresult = await axios.post(process.env.REACT_APP_API_URL+"/api/productcount", { searchValue: searchData ? "" : searchvalue }, { headers: headers });
      let countresponse = JSON.parse(countresult.request.response)
      let totalpage = Math.ceil(countresponse / limit);
      return totalpage;
    }
    catch (err) {
      if (err.response.status == 404) {
        return 1;
      }
    }
  };

  useEffect(async () => {
    setload("border") //set loader
    const totalPages = await getTotalPages(); // total pages count 
    setpageCount(totalPages); // set total pages count 
    let results = await axios.post(process.env.REACT_APP_API_URL+"/api/productlist", { pageNumber: pageCount, searchValue: searchvalue }, { headers: headers });
    let response = JSON.parse(results.request.response)
    if (response.length) {
      setload("")
      setData(response)
    }
  }, []);

  const handlePageClick = async (data) => {
    setpageNo(data.selected);
    setData({})
    setload("border")
    let currentPage = data.selected + 1;
    const totalPages = await getTotalPages(); // total pages count
    setpageCount(totalPages); // set total pages count 
    const productsdata = await fetchlist(currentPage);
    setData(productsdata);
    setload("");
  };

  const fetchlist = async (currentPage) => {
    let results = await axios.post(process.env.REACT_APP_API_URL+"/api/productlist", { pageNumber: currentPage, searchValue: searchvalue }, { headers: headers });
    let response = JSON.parse(results.request.response)
    return response;
  };

  const onreset = async () => {
    setpageNo(0);
    setsearchvalue("");
    setload("border")
    const totalPages = await getTotalPages(true); // total pages count 
    setpageCount(totalPages); // set total pages count 
    let results = await axios.post(process.env.REACT_APP_API_URL+"/api/productlist", { pageNumber: 1, searchValue: "" }, { headers: headers });
    let response = JSON.parse(results.request.response)
    if (response.length) {
      setload("")
      setData(response)
    }
  };

  const handlesubmit = async (e) => {
    setData({})
    setload("border")
    setpageNo(0)
    const totalPages = await getTotalPages(); // total pages count 
    setpageCount(totalPages);
    if (totalPages >= 1) {
      let results = await axios.post(process.env.REACT_APP_API_URL+"/api/productlist", { pageNumber: 1, searchValue: searchvalue }, { headers: headers });
      let response = JSON.parse(results.request.response)
      if (response.length) {
        setload("")
        setData(response)
      }
      else {
        swal({
          title: "Data not found",
          icon: "error",
        });
        onreset();
        setload("");
      }
    }


  }
  const handlechange = async (e) => {
    e.preventDefault();
    if (e.target.value == "") {
      setsearchvalue(e.target.value.trim())
    } else {
      setsearchvalue(e.target.value.trim())

    }
  }
  const deletescanableproduct = async (id, index) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          const url = `${process.env.REACT_APP_API_URL}/api/deletescanableproduct/${id}`;
          const options = {
            method: 'delete',
            headers:headers,
          };
          fetch(url, options)
            .then(response => response.json())    // one extra step
            .then(datas => {
              if (datas.status == 200) {
                const list = [...data]
                list.splice(index, 1)
                setData(list)
              }
            })
          swal("success! Product Info has been deleted!", {
            icon: "success",
          });
        } else {
          // swal("Your imaginary file is safe!");
        }
      });


  };
  
  let loaderstyle = { width: "2rem", height: "2rem", marginLeft: "15%", marginTop: "15%", display: "", position: "absolute" }
  let loadernostyle = { width: "0rem", height: "0rem", display: "none", position: "absolute" }
  let displayhide = { display: "none" }
  let displayshow = { display: "", boxShadow: '2px 2px 10px black', borderRadius: "50%", backgroundColor: "white", width: "3em", height: "3em", marginLeft: "35%", marginTop: "15%", position: "absolute" }
  return (
    <>
      <Sidebar />
      <div className="userList">
        <div className="search_bar_recycli">
        <Link to={"/Products/Create"} >
            <button type="button" className="userListEdit" > &nbsp;&nbsp;Add New</button>
          </Link>
          <form onSubmit={(e) => {
            e.preventDefault();
            if (!searchvalue == "") {
              handlesubmit(e)
            }

          }}>

            <div>
              <input type="text" name="searchvalue" className="userUpdateInputscan" placeholder=" Search" onChange={(e) => { handlechange(e) }} value={searchvalue}></input>
              <Button className="search_btn" type="submit"><SearchIcon></SearchIcon></Button><Button className="reset_btn" style={{ marginLeft: '10px' }} onClick={onreset}>Reset</Button>
            </div>
          </form>
        </div>

        <div style={load ? displayshow : displayhide}>
          <Spinner animation={load} role="status" style={load ? loaderstyle : loadernostyle}>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
        <div className="custom_data_table">
          <Table>
            <thead>
              <tr>
                {/* <th>Id</th> */}
                <th>Product name</th>
                <th>Product code</th>
                <th>Brand</th>
                <th>Count</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.length ? data.map((element, index) => {
                return (<tr key={index}>
                  <td>{element.product_name}</td>
                  <td>{element.code}</td>
                  <td>{element.brands}</td>
                  <td>{element.count ? element.count : 0}</td>
                  <td>
                    <>
                      <Link to={"/Products/" + element.code} state={{ productnamebefore: element.product_name }}>
                        <button className="viewButton"><VisibilityIcon /></button>
                      </Link>
                      <Link to={"/Products/Edit/"} state={{ element }}>
                        <button className="editButton"><EditIcon /></button>
                      </Link>
                      <button className="deleteButton" ><DeleteIcon onClick={() => deletescanableproduct(element._id, index)} /></button>
                    </>
                  </td>
                </tr>)
              }) : ""}
            </tbody>
          </Table>
        </div>
        {/* {load?"":
      hideonsearch?"":
      <div className="d-flex justify-content-between">
      <button type="button" disabled = {pageCount<="1"?"true":""} className="prev_btn btn btn-dark" onClick={handlePrevClick}>Prev</button>
      <button type="button" className="next_btn btn btn-dark" onClick={handleNextClick}>Next</button>
    </div>} */}
        {/* {hideonsearch?"":
      <div className="container d-flex justify-content-between">
      <button type="button" disabled = {pageCount<="1"?"true":""} className="btn btn-dark" onClick={handlePrevClick}>Prev</button>
      <button type="button" className="btn btn-dark" onClick={handleNextClick}>Next</button>
    </div>} */}
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          forcePage={pageNo}
          containerClassName={"pagination justify-content-space-between"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      </div></>
  );
}
