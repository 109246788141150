import "./nonscanableproduct.css";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Spinner from 'react-bootstrap/Spinner';
import Sidebar from "../../components/sidebar/Sidebar";
import SearchIcon from '@mui/icons-material/Search';
import swal from 'sweetalert';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import axios from "axios";
import base64 from "base-64";

export default function NonScanableProduct() {
  const [data, setData] = useState({});
  const [load, setload] = useState("");
  const [pageCount, setpageCount] = useState(1);
  const [searchvalue, setsearchvalue] = useState("");
  const [pageNo, setpageNo] = useState(0);

  let limit = 10;
  const auth=  "Basic "+base64.encode(process.env.REACT_APP_USER_KEY+":"+process.env.REACT_APP_PWD_KEY);
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': auth,
  }
  const getTotalPages = async (searchData=false) => {
    try{
    let countresult = await axios.post(process.env.REACT_APP_API_URL+"/api/notfoundcount", { searchValue: searchData?"":searchvalue },{headers:headers});
    let countresponse = JSON.parse(countresult.request.response)
    let totalpage = Math.ceil(countresponse / limit);
    return totalpage;
    }
    catch(err){
      if(err.response.status==404){
        return 1;
      }
    }
  };
  // first time render
  useEffect(async () => {
    setload("border") //set loader
    const totalPages = await getTotalPages(); // total pages count 
    setpageCount(totalPages); // set total pages count 
    let results = await axios.post(process.env.REACT_APP_API_URL+"/api/notfoundproductlist", { pageNumber: pageCount, searchValue: searchvalue },{headers:headers});
    let response = JSON.parse(results.request.response)
    if (response.length) {
      setload("")
      setData(response)
    }
  }, []);

  const handlePageClick = async (data) => {
    setpageNo(data.selected);
    setData({})
    setload("border")
    let currentPage = data.selected + 1;
    const totalPages = await getTotalPages(); // total pages count 
    setpageCount(totalPages); // set total pages count 
    const productsdata = await fetchlist(currentPage);
    setData(productsdata);
    setload("");
  };

  const fetchlist = async (currentPage) => {
    let results = await axios.post(process.env.REACT_APP_API_URL+"/api/notfoundproductlist", { pageNumber: currentPage, searchValue: searchvalue },{headers:headers});
    let response = JSON.parse(results.request.response)
    return response;
  };
  const deletenonscanableproduct = async (id, index) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Data",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          const url = `${process.env.REACT_APP_API_URL}/api/deletenonscanableproduct/${id}`;
          const options = {
            method: 'delete',
            headers:headers
          };
          fetch(url, options)
            .then(response => response.json())    // one extra step
            .then(datas => {
              if (datas.status == 200) {
                const list = [...data]
                list.splice(index, 1)
                setData(list)
              }
            })
          swal("Success! Product has been deleted!", {
            icon: "success",
          });
        }
      });
  }
  const onreset = async () => {
    setpageNo(0);
    setsearchvalue("");
    setload("border")
    const totalPages = await getTotalPages(true); // total pages count 
    setpageCount(totalPages); // set total pages count 

    let results = await axios.post(process.env.REACT_APP_API_URL+"/api/notfoundproductlist", { pageNumber: 1, searchValue: "" },{headers:headers});
    let response = JSON.parse(results.request.response)
    if (response.length) {
      setload("")
      setData(response)
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    setpageNo(0)
    setData({})
    setload("border")
    const totalPages = await getTotalPages(); // total pages count 
    setpageCount(totalPages); // set total pages count 
    if (totalPages >= 1) {
      let results = await axios.post(process.env.REACT_APP_API_URL+"/api/notfoundproductlist", { pageNumber: 1, searchValue: searchvalue },{headers:headers});
      let response = JSON.parse(results.request.response)
      if (response.length) {
        setload("")
        setData(response)
      }
      else {
        swal({
          title: "Data not found",
          icon: "error",
        });
        onreset();
        setload("");
        // sethideonsearch(1)
      }
    }
  }
  const handlechange = async (e) => {
    e.preventDefault();
    if (e.target.value == "") {
      setsearchvalue(e.target.value.trim())
    } else {
      setsearchvalue(e.target.value.trim())
    }
  }

  let loaderstyle = { width: "2rem", height: "2rem", marginLeft: "15%", marginTop: "15%", display: "", position: "absolute" }
  let loadernostyle = { width: "0rem", height: "0rem", display: "none", position: "absolute" }
  let displayhide = { display: "none" }
  let displayshow = { display: "", boxShadow: '2px 2px 10px black', borderRadius: "50%", backgroundColor: "white", width: "3em", height: "3em", marginLeft: "35%", marginTop: "15%", position: "absolute" }

  return (
    <>
      <Sidebar />
      <div className="userList">
        <div className="search_bar">
          <form onSubmit={(e) => {
            e.preventDefault();
            if (!searchvalue == "") {
              handlesubmit(e)
            }

          }}>

            <div>
              <input type="text" name="searchvalue" className="userUpdateInputscan" placeholder=" Search" onChange={(e) => { handlechange(e) }} value={searchvalue}></input>
              <Button className="search_btn" type="submit"><SearchIcon></SearchIcon></Button><Button className="reset_btn" style={{ marginLeft: '10px' }} onClick={onreset}>Reset</Button>
            </div>
          </form>
        </div>
        <div style={load ? displayshow : displayhide}>
          <Spinner animation={load} role="status" style={load ? loaderstyle : loadernostyle}>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
        <div className="custom_data_table">
          <Table>
            <thead>
              <tr>
                {/* <th>Id</th> */}
                <th>Product name</th>
                <th>Product code</th>
                {/* <th>Further details</th> */}
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.length ? data.map((element, index) => {
                return (<tr key={index}>
                  {/* <td>{element._id}</td> */}
                  <td>{element.product_name}</td>
                  <td>{element.code}</td>
                  {/* <td>{element.further_details}</td> */}
                  <td>
                    <>
                      {/* <Link to={"/Products/Edit/"} state={{ element }}>
                        <button className="editButton"><EditIcon /></button>
                      </Link> */}
                      <Link to={"/NotFoundProducts/" + element._id} state={{ productnamebefore: element.product_name }}>
                        <button className="viewButton"><VisibilityIcon /></button>
                      </Link>
                      <Link to={"/NotFoundProductsCreate/"} state={{ element }}>
                        <button className="viewButton"><AddIcon /></button>
                      </Link>
                      <button className="deleteButton" onClick={() => deletenonscanableproduct(element._id, index)}><DeleteIcon /></button>
                    </>
                  </td>
                </tr>
                )
              }) : ""}
              {/* <tr>
          <th>#</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Username</th>
        </tr> */}
            </tbody>
          </Table>
        </div>

        {/* {load?"":hideonsearch?"":
      <div className="d-flex justify-content-between">
      <button type="button" disabled = {pageCount<="1"?"true":""} className="prev_btn btn btn-dark" onClick={handlePrevClick}>Prev</button>
      <button type="button" disabled = {nextshow?"true":""} className="next_btn btn btn-dark" onClick={handleNextClick}>Next</button>
    </div>}
{issearch?
      <div className="d-flex justify-content-between">
      <button type="button" className="prev_btn btn btn-dark" disabled = {issearchpageCount<="1"?"true":""} onClick={handleSearchPrevClick}>Prev</button>
      <button type="button" className="next_btn btn btn-dark" disabled={searchnextshow?"true":""} onClick={handleSearchNextClick}>Next</button>
    </div>:""} */}
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          forcePage={pageNo}
          containerClassName={"pagination justify-content-space-between"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />

      </div></>
  );
}
