import "./sidebar.css";
import not_found from '../image/no_product_found.png';
import products_icon from '../image/products_icon.png';
import location from '../image/marker.png';
import { NavLink, useNavigate } from "react-router-dom";
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';


export default function Sidebar() {
  const navigate = useNavigate();
  var loggedIn = window.localStorage.getItem("islogged");
  if (loggedIn != "1") {
    navigate('/');
  }
  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarMenu">

          <ul className="sidebarList">
            <NavLink activeclassname="active" to="/Products" style={{ textDecoration: 'none' }}>
              <li className="sidebarListItem" style={{ textDecoration: 'none' }}>
                <img className="sidebarIcon" src={products_icon} />
                Products
              </li>
            </NavLink>
            <NavLink activeclassname="active" to="/NotFoundProducts" style={{ textDecoration: 'none' }}>
              <li className="sidebarListItem" style={{ textDecoration: 'none' }}>
                <img className="sidebarIcon" src={not_found} />
                Not Found Products
              </li>
            </NavLink>
            <NavLink activeclassname="active" to="/Location" style={{ textDecoration: 'none' }}>
              <li className="sidebarListItem">
                <img className="sidebarIcon" src={location} />
                Recyclable Centers
              </li>
            </NavLink>
            <NavLink activeclassname="active" to="/locationRecyclability" style={{ textDecoration: 'none' }}>
              <li className="sidebarListItem">
                <img className="sidebarIcon" src={location} />
                Location 
              </li>
            </NavLink>
            <NavLink activeclassname="active" to="/categories" style={{ textDecoration: 'none' }}>
              <li className="sidebarListItem sidebarSubListItem" >
                 <SubdirectoryArrowRightIcon /> Categories
              </li>
            </NavLink>
            <NavLink activeclassname="active" to="/materials" style={{ textDecoration: 'none' }}>
              <li className="sidebarListItem sidebarSubListItem">
                <SubdirectoryArrowRightIcon /> Materials
              </li>
            </NavLink>
          </ul>
        </div>
      </div>
    </div>
  );
}