export const MATERIALRECYCLETYPES = [
    "Non-Recyclable General Waste",
    "Recycle Glass",
    "Recycle Card",
    "Recycle Can",
    "Recycle Paper",
    "Recycle Bottle",
    "Recycle",
    "Non-Recyclable",
    "Recycle aluminium",
    "Recycle Plastic",
    "Non-Recyclable at Kerb",
    "Recycle aluminium foil",
    "Plastic Non-Recyclable",
    "Recycle Tray / Film Non-Recyclable",
    "Recycle Card / Plastic wrap non-Recyclable",
    "Recycle Tray / Film Non-Recyclable / Recycle Card",
    "Recycle metal",
    "Recycle Tray / Recycle Card / Film Non-Recyclable",
    "Recycle Glass / Recycle plastic",
    "Recycle Card / Recycle metal Can",
    "Recycle Card / wrapping Non-Recyclable",
    "Recycle Card / Film Non-Recyclable",
    "Recycle Card / Plastic Non-Recyclable",
    "Recycle Foil / Cardboard Non-Recyclable",
    "Recycle Card / Plastic jar / Rest Non-Recyclable",
    "Carton Non-Recyclable",
    "Recycle pot / film Non-Recyclable",
    "Recycle Tub / Recycle Card / Film Non-Recyclable",
    "Recycle Foil",
    "Recycle Jar",
    "Recycle Hard plastic / Soft Plastic Non-Recyclable",
    "Recycle Bottle / Cork Non-Recyclable",
    "Recycle Glass /Rest Non-Recyclable",
    "Recycle Bottle / rest Non-Recyclable",
    "Recycle Card / Rest Non-Recyclable",
    "Recycle Card / Recycle Plastic",
    "Recycle Hard plastic / Soft Plastic wrap Non-Recyclable",
    "Recycle Card / Recycle Hard plastic / Soft Plastic wrap Non-Recyclable",
    "Recycle Card / Recycle Hard plastic / Soft Plastic Non-Recyclable",
    "Recycle aluminium foil / tray",
    "Recycle glass / recycle plastic bottle",
    "Plastic wrap Non-Recyclable",
    "Recycle Foil / Recycle Card",
    "Recycle Card / Recycle Hard plastic / Soft Plastic Non-Recyclable at Kerb",
    "Soft Plastic Non-Recyclable at Kerb",
    "Recycle plastic bottle / rest Non-Recyclable",
    "Recycle Glass / Recycle Paper",
    "Film Non-Recyclable / hard plastic recycle",
    "Recycle jar / Plastic wrap Non-Recyclable",
    "Recycle tub / Wrap Non-Recyclable",
    "Non-Recyclable at Kerb / plastic wrap Non-Recyclable",
    "Recycle aluminium foil / Recycle Card",
    "Recycle Can / bottle",
    "Recycle Paper / Rest Non-Recyclable",
    "Recycle Can / Recycle Hard plastic / Soft Plastic Non-Recyclable",
    "Recycle Hard plastic / Soft Plastic Non-Recyclable / Rest Non-Recyclable",
    "Recycle Card / carton Non-Recyclable at Kerb",
    "Carton Non-Recyclable / Recycle plastic bottle",
    "Recycle bottle / can",
    "Recycle metal / rest Non-Recyclable",
    "Recycle tub /  film Non-Recyclable",
    "Recycle bottle / can / Rest Non-Recyclable",
    "Recycle tub / Film Non-Recyclable",
    "Plastic wrap / film Non-Recyclable",
    "Recycle Card / Recycle plastic pot / Rest Non-Recyclable",
    "Recycle Hard plastic / Soft Plastic wrap Non-Recyclable at Kerb",
    "Recycle tin foil",
    "Plastic wrap Non-Recyclable / Recycle Tray",
    "Plastic wrap Non-Recyclable / Recycle tub",
    "Recycle Hard plastic / Soft Plastic Non-Recyclable / Plastic wrap Non-Recyclable",
    "Recycle Card / Hard plastic / Soft Plastic  Non-Recyclable",
    "Recycle Card / Hard plastic / Plastic wrap Non-Recyclable",
    "Card Recyclable / Plastic Non-Recyclable",
    "Film Non-Recyclable / Recycle Rest",
    "Recycle Glass / Card / Rest Non-Recyclable",
    "Recycle carton",
    "Recycle glass / rest Non-Recyclable",
    "Recycle Metal / Carton Non-Recyclable",
    "Recycle Metal / Recycle Card",
    "Recycle Can / Recycle Glass",
    "Recycle Glass / Plastic Non-Recyclable",
    "Recycle Glass / Carton Non-Recyclable"
];

export const CATEGORYRECYCLETYPES = [
    "Recycle Glass / Recycle Card / Recycle Can",
    "Recycle pot / film non recyclable / Carton not recyclable",
    "Carton not Recyclable",
    "Recycle Tub / Film Non-Recyclable",
    "Recycle Glass /  Film Non-Recyclable",
    "Recycle Tray / Film Non-Recyclable",
    "Carton not Recyclable / Recycle Glass / Recycle plastic bottle",
    "Non-Recyclable General Waste",
    "Recycle Card / Plastic wrap non-Recyclable at kirb",
    "Recycle Glass /  Film Non-Recyclable / Recycle plastic bottle",
    "Recycle Can / Recycle Card",
    "Film Non-Recyclable / Recycle card",
    "Recycle Tray / Film Non-Recyclable / Wrap not recyclable",
    "Recycle Glass / Rest non-Recyclable",
    "Recycle Tray / Film Non-Recyclable / Recycle card",
    "Recycle Card / Plastic wrap non-Recyclable",
    "Film Non-Recyclable",
    "Recycle pastic bottle / Rest non-recyclable",
    "Film Non-Recyclable at kirb",
    "Recycle Card / Recycle Tray/ Plastic wrap non-Recyclable",
    "Soft Plastic Non-Recyclable at Kerb",
    "Recycle Tray / Plastic wrap non-Recyclable",
    "Recycle Glass /  Film Non-Recyclable / Recycle plastic jar",
    "Recycle Card / Reycle tin / Soft plastic non-Recyclable",
    "Recycle Glass / Recycle Plastic Bottle / Soft plastic non-Recyclable",
    "Recycle Can / Recycle Tub / Soft plastic wrap non-Recyclable",
    "Reycle tin / Soft plastic wrap non-Recyclable",
    "Plastic wrap non-Recyclable",
    "Plastic wrap non-Recyclable at kirb",
    "Carton not Recyclable / Recycle Plastic bottle",
    "Recycle Tub / Film Non-Recyclable / Recycle plastic bottle",
    "Plastic wrap non-Recyclable at kirb / Recycle plastic tub",
    "Plastic wrap non-Recyclable at kirb / Recycle plastic tub / Recycle card",
    "Recycle pot / film non recyclable",
    "Recycle Tub / Film Non-Recyclable / Recycle card",
    "Recycle Card / Recycle Bottle / Plastic wrap non-Recyclable",
    "Recycle Card / Reycle tin / Soft plastic non-Recyclable at kirb",
    "Recycle Glass  / Recycle plastic bottle",
    "Recycle Hard plastic / Soft Plastic Non-Recyclable",
    "Recycle Can / Film Non-Recyclable at kirb"
]